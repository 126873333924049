import './ui-elements'
import './form'
import './popup'
// import './animation'


$(function () {
  'use strict';
    

  if($("div").is(".js-main-slider")){ 
    var mainSlider = new Swiper('.js-main-slider', {
      speed: 1000,
      effect: 'fade', 
      autoplay: {
        delay: 5000,
      },

      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.js-main-button-next',
        prevEl: '.js-main-button-prev',
      }
    });
  }

	$('.js-nav-btn').on('click',function(){	
		var $this = $(this);
		var navBlock = $('.js-menu-wrap');
		$this.toggleClass('burger_active');
		if ($this.hasClass('burger_active')) {
				navBlock.animate({"left":"0px"});	
			$('.modal-bg').fadeIn();	
		  } else {
				navBlock.animate({"left":"-100%"});	
			$('.modal-bg').fadeOut();			
		  } 
	
	});

  if($('.home-menu').length) {
    $(".js-menu-anchor a").click(function (e) {
  
      if($(window).width()<=992){
        var navBlock = $('.js-menu-wrap');
        navBlock.animate({"left":"-100%"});	
        $('.js-nav-btn').removeClass('burger_active');
      }
      
      e.preventDefault();
      var _href = $(this).attr("href").split('#').pop();	
      console.log(_href);
      $("html, body").animate({
        scrollTop: ($("#"+_href).offset().top-100) + "px"
      });
      return false;
    });
  } 

  $(window).scroll(function(){
		var aTop = 0;
  
		var navblock= $('header'); 
		if($(this).scrollTop()>aTop){
			navblock.addClass('fixed');
		} else{
			navblock.removeClass('fixed');
		}
	});

  $(".js-anchor").click(function () {
    var _href = $(this).attr("href");
    $("html, body").animate({
      scrollTop: ($(_href).offset().top-100) + "px"
    });
    return false;
  });

  // Fancybox.bind("[data-fancybox]", {});
	// console.log(Fancybox);


  $('.tabs-header .tab-btn').click(function(e){
    e.preventDefault();
    $(this).closest('.tabs-box').find('.tab-btn').removeClass('active');
    $(this).addClass('active');
    let index=$(this).data('tab');
    $(this).closest('.tabs-box').find('.content-tab').removeClass('active');
    $(this).closest('.tabs-box').find('.content-tab_'+index).addClass('active');
   

    $(this).closest('.programms').find('.tabs-imgs .tabs-img').removeClass('active');
    $(this).closest('.programms').find('.tabs-img_'+index).addClass('active');

  });

  $('input[name=donation_custom]').on('focus',function(){
    $('input[name=donation]').prop('checked', false);
  });

  $('input[name=donation]').on('click',function(){
    $('input[name=donation_custom]').val('');
  });

 
  // Today slider thumbs
  var cartThumbs = new Swiper('.js-cart-thumbs', {
    slidesPerView: 'auto',
    spaceBetween: 5,
    freeMode: true,
    speed: 1000,
    watchSlidesProgress: true,
    on: {
      slideChange: function slideChange() {
        if (cartThumbs.isEnd) {
          $('.js-thumb-button-next').addClass('hide');
        } else {
          $('.js-thumb-button-next').removeClass('hide');
        }

        if (cartThumbs.isBeginning) {
          $('.js-thumb-button-prev').addClass('hide');
        } else {
          $('.js-thumb-button-prev').removeClass('hide');
        }
      }
      // breakpoints: {
      //   480: {
      //     slidesPerView: 4.5
      //   },
      //   768: {
      //     slidesPerView: 'auto'
      //   }
      // }
  } });

  // Today slider main (Parallax)
  var cartMainSliderSelector = '.js-cart-slider',
      interleaveOffset = 0.5;

  //count of today slider
  var countSliderCart = $('.js-cart-slider'),
      itemSliderToday = $('.cart-slider__slide');

  var cartMainSliderOptions = {
    loop: false,
    speed: 1000,
    initialSlide: 0,
    autoHeight: true,
    grabCursor: false,
    simulateTouch: true,
    watchSlidesProgress: true,
    thumbs: {
      swiper: cartThumbs
    },
    navigation: {
      nextEl: '.js-cart-next',
      prevEl: '.js-cart-prev'
    },
    on: {
      init: function init() {
        this.autoplay.stop();
      },
      progress: function progress() {
        var isIE = navigator.appVersion.indexOf('Edge') > -1;
        if (isIE) return;
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          var slideProgress = swiper.slides[i].progress,
              innerOffset = swiper.width * interleaveOffset,
              innerTranslate = slideProgress * innerOffset;
          swiper.slides[i].querySelector('.slide-bgimg').style.transform = 'translate3d(' + innerTranslate + 'px, 0, 0)';
        }
      },
      touchStart: function touchStart() {
        var isIE = navigator.appVersion.indexOf('Edge') > -1;
        if (isIE) return;
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = '';
        }
      },
      setTransition: function setTransition(speed) {
        var isIE = navigator.appVersion.indexOf('Edge') > -1;
        if (isIE) return;
        var swiper = this;
        for (var i = 0; i < swiper.slides.length; i++) {
          swiper.slides[i].style.transition = speed + 'ms';
          swiper.slides[i].querySelector('.slide-bgimg').style.transition = speed + 'ms';
        }
      }
    }
  };

  var todayMainSlider = new Swiper(cartMainSliderSelector, cartMainSliderOptions);

  $('.js-thumb-button-next').on('click', function () {
    cartThumbs.slideNext(500);
  });

  $('.js-thumb-button-prev').on('click', function () {
    cartThumbs.slidePrev(500);
  });



  
    var wind = $(window),
        body = $('body'),
        btnSearch = $('.js-hdr-search-toggle'),
        mobMenuBtnToggle = $('.js-hdr-menu-toggle');
  
    function onScroll() {
      var scroll = wind.scrollTop();
      if (scroll > 200) {
        body.addClass('_header-fixed');
      } else {
        body.removeClass('_header-fixed');
      }
    }
  
    onScroll();
    wind.scroll(function (event) {
      onScroll();
    });
  
    btnSearch.bind('click', function () {
      var status = body.hasClass('_mob-search-open');
      status ? body.removeClass('_mob-search-open') : body.addClass('_mob-search-open');
    });
  
    mobMenuBtnToggle.bind('click', function () {
      var status = body.hasClass('_mob-menu-open');
      status ? body.removeClass('_mob-menu-open') : body.addClass('_mob-menu-open');
    });


		let month =['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
		let days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    $('.js-date-init').each(function(){
      var contradate_item = datepicker(this,{
        customOverlayMonths: month,
        customMonths: month,
        customDays: days,
        maxDate: new Date(),
        formatter: (input, date, instance) => {
          const value = date.toLocaleDateString()
          input.value = value // => '1/1/2099'
        }
      });
    });


    function send_sms(){

			var sended_code = Math.floor(Math.random()*(999999-100000+1)+100000); 
			var smsFormData = new FormData();
			smsFormData.append('smscode',sended_code);
			smsFormData.append('phonenumber',$('input[name="phonenumber"]').val());
			
			$.ajax({
				url: 'sms-test.php',
				method: 'post',
				cache: false,
				contentType: false,
				processData: false,
				data: smsFormData,
				dataType : 'json',
				success: function(data) {
					console.log(data);							
					return false;								
				},
				error: function (jqXHR, exception) {
						return false;
				}
			});
		
			$('.js-code-warning').text('Код отправлен на ваш номер телефона');
		
			$.fancybox.open({
				src: '#confirm_sms',
				type: 'inline' 
			 });	
		 		
			return sended_code;
		
		}		


    
function imageUpload() {

  var docsFormat = 'jpeg,png,gif,jpg';

  $('.js-img-input').each(function () {
    var self = $(this),

    //data = self.data(),
    target = $('.js-img-input_target', self),
        input = $('input[type="file"]', self),
        clone;

    self.bind('click', function (e) {
      clone.val(null);
      input.val(null);
      self.removeClass('_has-img');
      target.removeClass('icon');
      e.stopPropagation();
      //     if(input[0].files[0]) {
      //       input.replaceWith(clone[0]);
      //       input = $('input[type="file"]', self);
      //       clone = input.clone(true, true);
      //       target.css('background-image', '');
      //       self.removeClass('_has-img');
      //       //e.preventDefault();
      //     }
    });
    input.bind('change input', function (e) {
      var formatId = input[0].files[0].name.lastIndexOf('.') + 1;
      var formatTxt = input[0].files[0].name.slice(formatId);
      target.attr('title', input[0].files[0].name);
      if (docsFormat.indexOf(formatTxt) >= 0) {
        target.addClass('icon');
        self.addClass('_has-img');
      } else {
        var reader = new FileReader(),
            file = input[0].files[0];
        reader.onload = function (thisFile) {
          return function (e) {
            var strDataUrl = e.target.result,
                newImg = new Image();
            newImg.src = strDataUrl;
            target.css('background-image', 'url(\'' + strDataUrl + '\')');
            self.addClass('_has-img');
          };
        }(file);
        if (file) {
          reader.readAsDataURL(file);
        } else {
          target.css('background-image', '').attr('title', '');
          self.removeClass('_has-img');
        }
      }
    });
    clone = input.clone(true, true);
  });
}
imageUpload();



  });


