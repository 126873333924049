$(function () {
	let $popup = $('.js-popup');
	let $popupCloseBtn = $('.js-popup-close');
	let lastOpenedPopup = null;
	let scrollPosition;

	if (location.hash !== '') {
		$(`.js-popup[data-block='${location.hash.substr(1)}']`).addClass('is-show');
		scrollPosition = $(window).scrollTop();
	}
	

	$('body').on('click','.js-popup-link',function(e){
		e.preventDefault();
		let $this = $(this);
	
		let target = $this.attr('data-link');
		let href = $this.attr('data-href');
		let videoSrc = $this.attr('data-src');
		let action = $this.attr('data-action');
		let post_id = $this.attr('data-post_id');

		openPopup(target, href, videoSrc,action,post_id);

		if (typeof $this.data('scrollto') !== 'undefined' ) {
			setTimeout(function () {
				let form = $('.js-ajax-wrapper').find('form');
				if (form.length) {
					form.find('input:not([type="hidden"])').first().focus();
				}
			}, 1500);
		}
		return false;
	});



	function openPopup(target, href, videoSrc,action, post_id) {
	
		const $elem = $('.js-popup[data-block="'+target+'"]');
		$popup.removeClass('is-show');
		$elem.addClass('is-show');

		scrollPosition = $(window).scrollTop();


		// if(action=="load_post") {
		// 	let data={
		// 		action: action,
		// 		post_id: post_id
		// 	}
		// 	ajax_action(data,$('.js-ajax-wrapper'));
		// }
		// if(action=="load_product") {
		// 	let data={
		// 		action: action,
		// 		post_id: post_id
		// 	}
		// 	ajax_action(data,$('.js-ajax-product'));
		// }

	}

	window.openPopup = openPopup;

	$popupCloseBtn.on('click',function(){
		let $this = $(this);
		let $popup = $this.closest('.js-popup');

		$popup.removeClass('is-show');
		$popup.find('.js-iframe').attr('src','');
		// location.hash = '';
		if (scrollPosition !== "undefined") {
			$(window).scrollTop(scrollPosition);
			scrollPosition = undefined;
		}

		if (lastOpenedPopup === 'href') {
			history.back();
			let scrollPositionLast = $(window).scrollTop();
			// setTimeout(function(){
			// 	location.hash = ' '
			// }, 500);
			lastOpenedPopup = null;
		}

	});

	// function ajax_action(action_data,result_box){
	// 	result_box.empty();
	
	// 	$.ajax({
	// 		type: 'POST',
	// 		url: '/wp-admin/admin-ajax.php',
	// 		data: action_data, 
	// 		error: function (jqXHR, exception) {
	// 		},
	// 		beforeSend : function ( xhr ) {		
			 
	// 		},
	// 		success : function( data ){   			
	// 			if (data) {
	// 				result_box.html(data);   

	// 				initSlider();
	// 			} else {
				 
	// 			}
	// 		}
	// 	});
	// }


	// function initSlider(){

	// 	if($("div").is(".product-main-init")){ 
	// 		var galleryThumbs = new Swiper('.product-thumb-init', {
	// 			spaceBetween: 10,		
	// 			slidesPerView: 3,
	// 			freeMode: true,	
	// 			watchSlidesVisibility: true,
	// 			watchSlidesProgress: true,
	// 			breakpoints: {
	// 				1300: {
	// 				spaceBetween: 10
	// 				}
	// 			},
	// 			navigation: {
	// 				nextEl: '.js-prod-next',
	// 				prevEl: '.js-prod-prev',
	// 			},
	// 		});
	// 		var galleryTop = new Swiper('.product-main-init', {
	// 			spaceBetween: 0,	
	// 			slidesPerView: 1,		
	// 			thumbs: {
	// 				swiper: galleryThumbs
	// 			}
	// 		});
	// 	}


	// }

});
