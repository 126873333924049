$(function () {

	// Ввод только цифр
		$('.js-number').keypress(function(e){
			let charCode = (e.which) ? e.which : e.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57)) {
				return false;
			}
		});
	
	// Деактивация кнопки отправки формы при снятии галочки с чекбокса согласия
		$('body').on('change', '.js-agree', function () {
			let $this = $(this);
			let btn = $this.closest('.js-form').find('.submit');
			if ($this.is(':checked')) {
				btn.removeAttr("disabled");
			} else {
				btn.attr("disabled", true);
			}
		});
	
	
	// Валидация формы
		let formValidate = function() {	
			let form = $('.js-form');	
			form.each(function(){
				let thisForm = $(this);	
				thisForm.validate({
					focusInvalid: false,
					highlight: function(element) {
						$(element).closest(".form-field").addClass('form-field--error');
						thisForm.find('button').prop("disabled", true);
					},
					unhighlight: function(element) {
						$(element).closest(".form-field").removeClass('form-field--error');
						thisForm.find('button').prop("disabled", false);
					},
					submitHandler: function(form, event){		
						event.preventDefault();					
							let formData = new FormData(thisForm[0]);					
							$.ajax({
								url: '/wp-admin/admin-ajax.php',
								type: 'POST',
								processData: false,
								contentType: false,
								data: formData,
								beforeSend: function() {							
									thisForm.find('button').attr('disabled', 'disabled');
								},
								success: function(data) {
								
									thisForm.find('button').prop('disabled', false);
	
									let $popup = $('.js-popup');
									$popup.removeClass('is-show');
	
									const $elem = $('.js-popup[data-block="'+thisForm.data('thank')+'"]');
									$elem.addClass('is-show');
	
									setTimeout(function() {
										$popup.removeClass('is-show');
										thisForm.trigger("reset");
									}, 3000);
	
	
	
								},
								error: function(error) {
								
								}
							});	
						return false;
					}
				});	
			});
		};
	
		formValidate();
	
		window.formValidate = formValidate;
	
		$B.on('click', 'label.error',function(){
			$(this).hide().siblings('input,textarea').focus();	
		});
	
		$B.on('keyup', 'input',function(){
			$(this).siblings('label.error').hide();
		});
	
		$B.on('change', '.js-select', function(){
			console.log('rfd')
			$(this).closest('.form-field').removeClass('form-field--error');
		})
	
		$('.popup-thank').on('click', function(){
			$(this).removeClass('is-show');
		});	


	let sms_code="";

	$('.js-send-sms').on('click', function(){
		$(this).removeClass('is-show');
		$(this).hide();
		sms_code=send_sms();
	});

 // registration form
 	$('.js-reg-form').submit(function(e) {
		e.preventDefault();
		let formData = new FormData($(this)[0]);    
			
		for (var pair of formData.entries()) {
			console.log(pair[0]+ ', ' + pair[1]);
		}  


	


		if($('.js-reg-form input[name=password]').val()==$('.js-reg-form input[name=password2]').val()) {

			sms_code=send_sms();

			$('.js-accept-sms').on('click', function(e){
				e.preventDefault();
				$(this).removeClass('is-show');
				let entered_sms=$('input[name=sms]').val(); 

				if(sms_code==entered_sms) {	
					
					$.ajax({
						url: '/wp-admin/admin-ajax.php',
						type: 'POST',
						processData: false,
						contentType: false,
						data: formData, 
						dataType: "json",
						success: function(result) {
							console.log(result);
							
							$('.js-popup').removeClass('is-show');			

							


								$('.js-popup').removeClass('is-show');			

								$('.js-popup[data-block="reg-success"]').addClass('is-show');

								// $('.reg-info').text(result.data.message);
					
								// setTimeout(function() {
								// 	window.location.replace(result.data.redirect);
			
								// }, 5000);
							
						} 
					});   

				
				} else {
					$(".js-sms-warning").text("Код не верен");
				}
				// $('.js-popup').removeClass('is-show');			
			});	

			$(".reg-info").text("");
		}

		else {
			$(".reg-info").text("Пароли не совпадают");
		}
		

 
		
	});




	function countDown(elm, duration, fn){
		var countDownDate = new Date().getTime() + (1000 * duration);
		var x = setInterval(function() {
			var now = new Date().getTime();
			var distance = countDownDate - now;
			var days = Math.floor(distance / (1000 * 60 * 60 * 24));
			var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
			var seconds = Math.floor((distance % (1000 * 60)) / 1000);
			elm.innerHTML = minutes + "м " + seconds + "с ";
			if (distance < 0) {
				clearInterval(x);
				fn();
				elm.innerHTML = "";
				$('.sms-countdown').hide();
			}
		}, 1000);
	}



	
	function send_sms(){

		var sended_code = Math.floor(Math.random()*(999999-100000+1)+100000); 
		// var smsFormData = new FormData();
		// smsFormData.append('smscode',sended_code);
		// smsFormData.append('phonenumber',$('input[name="phonenumber"]').val());
		

		// $.ajax({
		// 	url: 'sms-test.php',
		// 	method: 'post',
		// 	cache: false,
		// 	contentType: false,
		// 	processData: false,
		// 	data: smsFormData,
		// 	dataType : 'json',
		// 	success: function(data) {
		// 		console.log(data);							
		// 		return false;								
		// 	},
		// 	error: function (jqXHR, exception) {
		// 			return false;
		// 	}
		// });
	
		$('.js-code-warning').text('Код отправлен на ваш номер телефона');

		$('.js-accept-sms').show();
		// $.fancybox.open({
		// 	src: '#confirm_sms',
		// 	type: 'inline' 
		//  });	

		$('.js-popup').removeClass('is-show');			

		$('.js-popup[data-block="sms"]').addClass('is-show');
		$('.sms-countdown').show();

		alert(sended_code);

		countDown(document.querySelector('.sms-countdown .time'),120, function(){
			$('.modal .js-send-sms').show();
			$('.js-accept-sms').hide();
			$('.sms-countdown').show();
		
			$('.js-send-sms').show();
		})

		return sended_code;
	
	}		



	// login form
	$('.js-login-form').submit(function(e) {
		e.preventDefault();
		let thisForm = $(this);     
		let formData = new FormData($(this)[0]);     
		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData, 
			dataType: "json",
			success: function(result) {     
				console.log(result);
				if(result.success){         
					$('.login-info').text(result.data.message)
					setTimeout(function(){
						window.location.replace(result.data.redirect);
					},3000)
				} 
				
			}
		});
	});

	// add review
	$('.js-add-review').submit(function(e) {
		e.preventDefault();

		let thisForm = $(this);
		let formData = new FormData($(this)[0]);         
			$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData, 
			// dataType: "json",
			success: function(result) {
				console.log("result")
				if(result.success){

					$('.modal').removeClass('is-show');
					
					let success_id=thisForm.attr("data-thank"); 

						
					const $elem = $('.js-popup[data-block="'+success_id+'"]');
					$elem.addClass('is-show');

					setTimeout(function(){
						$('.modal').removeClass('is-show');
					},6000)


				}     
			}
		});
	});

	// add hotel
	$('.js-add-hotel').submit(function(e) {
		e.preventDefault();

		let thisForm = $(this);
		let formData = new FormData($(this)[0]);         
			$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData, 
			// dataType: "json",
			success: function(result) {
				console.log("result")
				if(result.success){

					$('.modal').removeClass('is-show');
					
					let success_id=thisForm.attr("data-thank"); 
						
					const $elem = $('.js-popup[data-block="'+success_id+'"]');
					$elem.addClass('is-show');

					setTimeout(function(){
						$('.modal').removeClass('is-show');
						location.reload();
					},6000)


				}     
			}
		});
	});

		// edit hotel
		$('.js-edit-hotel').submit(function(e) {
			e.preventDefault();
	
			let thisForm = $(this);
			let formData = new FormData($(this)[0]);         
				$.ajax({
				url: '/wp-admin/admin-ajax.php',
				type: 'POST',
				processData: false,
				contentType: false,
				data: formData, 
				// dataType: "json",
				success: function(result) {
					console.log("result")
					if(result.success){
	
						$('.modal').removeClass('is-show');
						
						let success_id=thisForm.attr("data-thank"); 
							
						const $elem = $('.js-popup[data-block="'+success_id+'"]');
						$elem.addClass('is-show');
	

	
	
					}     
				}
			});
		});

	// update user

 	$('.js-update-user').submit(function(e) {
		e.preventDefault();

		let formData = new FormData($(this)[0]);    

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData, 
			dataType: "json",
			success: function(result) {
				console.log(result);						

					$('.reg-info').text(result.data.message);
		
					setTimeout(function() {
						$('.reg-info').text("");

					}, 5000);
				
			} 
		});   

		

 
		
	});

	$('.js-delete').on('click', function(e){
		e.preventDefault();
		let post_id=$(this).attr("data-hotel_id");
		$('.js-del-yes').attr("data-post_id",post_id);
	});

	$('.js-set-id').on('click', function(e){
		e.preventDefault();
		let post_id=$(this).attr("data-hotel_id");
		$('.modal input[name=hotel_id]').val(post_id);
	});


	$('.js-del-yes').on('click', function(e){

		e.preventDefault();
		
		let post_id=$(this).attr("data-post_id");

		let formData = new FormData();    

		formData.append("action","del_object");

		formData.append("post_id",post_id);

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData, 
			dataType: "json",
			success: function(result) {
				if(result.success){         
					location.reload();
				}
			} 
		});   


	});


	// $('.js-find-object input').on('input', function() {

	// 	let text = jQuery(this).val();		
	// 	if(text.length>3){   	
	// 		let data={
	// 			'text': text,
	// 			'action': 'find_object'
	// 		};	 
	// 		jQuery.ajax({
	// 			type: 'POST',
	// 			url: '/wp-admin/admin-ajax.php',
	// 			data: data,
	// 			error: function (jqXHR, exception) {
	// 			},
	// 			beforeSend : function ( xhr ) { },
	// 			success : function( data ){
	// 				console.log(data); 
	// 				if (data) {
	// 					jQuery('.search-result').show();
	// 					jQuery('.search-result').append(data);        
	// 				} else {
	// 					jQuery('.search-result').hide();
	// 				}
	// 			}
	// 		});	
	// 	}
	 
	// });


	
	// jQuery(function($){
	// 	jQuery(document).mouseup( function(e){ 
	// 		var div = jQuery(".search-result" ); 
	// 		if ( !div.is(e.target) 
	// 				&& div.has(e.target).length === 0 ) { 
	// 			div.hide();
	// 		}
	// 	});
	// });

	// accept object


	$('.js-accept-object').on('click', function(e){

		e.preventDefault();
		
		let post_id=$(this).attr("data-post_id");
		let user_id=$(this).attr("data-user_id");

		let formData = new FormData();

		formData.append("action","accept_object");
		formData.append("post_id",post_id);
		formData.append("user_id",user_id);

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			type: 'POST',
			processData: false,
			contentType: false,
			data: formData, 
			dataType: "json",
			success: function(result) {
				if(result.success){         
				
					$('.modal').removeClass('is-show');				

					const $elem = $('.js-popup[data-block="accept-object"]');

					$elem.addClass('is-show');

					setTimeout(function(){
						location.reload();
					}, 3000);

				}
			} 
		});   


	});


	
	});
	
	
	